import React from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";
const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = React.useState(false);
  return (
    <div>
      <div className="header">
        <div className="logo">NYC FITNESS CLUB</div>
        {menuOpened === false && mobile === true ? (
          <div onClick={() => setMenuOpened(true)}>
            <img
              src={Bars}
              alt=""
              className="bars"
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </div>
        ) : (
          <ul className="header-menu">
            <li>
              <Link
                to="hero"
                onClick={() => setMenuOpened(false)}
                smooth={true}
                duration={1500}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="programs"
                onClick={() => setMenuOpened(false)}
                smooth={true}
                duration={1500}
              >
                Programs
              </Link>
            </li>
            <li>
              <Link
                to="reasons"
                onClick={() => setMenuOpened(false)}
                smooth={true}
                duration={1500}
              >
                Why us
              </Link>
            </li>
            <li>
              <Link
                to="plans-container"
                onClick={() => setMenuOpened(false)}
                smooth={true}
                duration={1500}
              >
                plans
              </Link>
            </li>
            <li>
              <Link
                to="testimonials"
                onClick={() => setMenuOpened(false)}
                smooth={true}
                duration={1500}
              >
                Testimonials
              </Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Header;
